import { NotificationModule } from "ditmer-embla";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CellProps, Column, Row, UseRowSelectRowProps } from "react-table";
import { nameof } from "ts-simple-nameof";
import classNames from "classnames";
import { Dropdown, DropdownOption } from "@components/dropdown/dropdown";
import Card from "@components/embla/card";
import { EmblaIcon, IconSize } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import ModalMessage from "@components/modal/modalMessage";
import { RoutePaths } from "@components/routing/routes";
import PaginatedTable from "@components/table/paginatedTable";
import { PaginatedListInput } from "@models/paginatedList/paginatedListInput";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import useDateFormatter from "src/hooks/useDateFormatter";
import Badge from "@components/embla/badge";
import {
  CaseElementTypeEnum,
  elementTypeIsCaseDocumentType,
  getCaseElementTypeDropdownOptions,
  getCaseElementTypes
} from "@services/api/case/models/caseElementTypeEnum";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import { AddDocumentsToDraft } from "@components/case/extractDraft/addDocumentsToDraft";
import { useDeleteExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import { UpdateExtractDraft } from "@pages/case/documents/addDocuments/createExtractDraft/updateExtractDraft";
import { CaseDocumentPaginatedListInput } from "@services/api/document/models/CaseDocumentPaginatedListInput";
import {
  useDeleteDocumentsMutation,
  useGetCaseDocumentsPaginatedQuery,
  useGetCaseDocumentUploadersQuery,
  useLazyGetCaseDocumentsExtractDraftInfoQuery
} from "@services/api/document/caseDocumentApi";
import { CaseDocumentPaginatedListModel } from "@services/api/document/models/caseDocumentPaginatedListModel";
import { ColumnSortInfo } from "@components/table/baseTable";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";
import Checkbox from "@components/checkbox/checkbox";
import { TableCell } from "@components/table/TableCell";
import useCaseAccess from "../hooks/useCaseAccess";
import { AddDocumentsModal } from "./addDocuments/addDocumentsModal";
import { EditDocument } from "./editDocuments/editDocuments";
import ExportCaseDocumentModal from "./exportDocuments/exportDocumentModal";
import styles from "./documentList.module.scss";
import { DocumentTitleCell } from "./DocumentTitleCell";

type DocumentListProps = {
  caseId: string;
  initialFilterInput: CaseDocumentPaginatedListInput;
  hideSearchFilter?: boolean;
  useExtraFilters?: boolean;
  tableTitle?: string;
  multiselectDelete?: boolean;
};

const initialSort: ColumnSortInfo = {
  columnName: "lastChangedAt",
  direction: "desc"
};

export const DocumentList: React.FC<DocumentListProps> = ({
  caseId,
  initialFilterInput,
  hideSearchFilter,
  useExtraFilters,
  tableTitle,
  multiselectDelete
}: DocumentListProps) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();
  const navigate = useNavigate();

  const { canDeleteDocuments } = useCaseAccess(caseId);
  const [caseDocumentsFilterInput, setCaseDocumentsFilterInput] =
    useState<CaseDocumentPaginatedListInput>(initialFilterInput);
  const {
    data: caseDocuments,
    isLoading: caseDocumentsIsLoading,
    isFetching: caseDocumentsIsFetching
  } = useGetCaseDocumentsPaginatedQuery({ caseId: caseId, input: caseDocumentsFilterInput });
  const { data: uploaders, isLoading: uploadersIsLoading } =
    useGetCaseDocumentUploadersQuery(caseId);
  const [deleteDocument, deleteRequest] = useDeleteDocumentsMutation();
  const [deleteDocumentsModel, setDeleteDocumentsModel] = useState<
    { ids: string[]; message: string } | undefined
  >(undefined);
  const [exportDocument, setExportDocument] = useState<CaseDocumentPaginatedListModel | undefined>(
    undefined
  );
  const [deleteExtractDraftId, setDeleteExtractDraftId] = useState<string | undefined>(undefined);
  const [addDocumentExtractDraftIds, setAddDocumentExtractDraftIds] = useState<
    { extractDraftId: string; documentIdString: string } | undefined
  >(undefined);
  const [editDocumentModal, setEditDocumentModal] = useState<CaseDocumentPaginatedListModel>();
  const [renameExtractDraft, setRenameExtractDraft] = useState<
    CaseDocumentPaginatedListModel | undefined
  >();
  const [deleteExtractDraft, deleteExtractDraftRequest] = useDeleteExtractDraftMutation();

  const [getExtractDraftInfoLazyQuery] = useLazyGetCaseDocumentsExtractDraftInfoQuery();

  useEffect(() => {
    if (deleteRequest.isSuccess) {
      NotificationModule.showSuccessSmall(localizer.theDocumentWasDeleted());
    }
  }, [deleteRequest.isSuccess, localizer]);

  useEffect(() => {
    if (deleteExtractDraftRequest.isSuccess) {
      NotificationModule.showSuccessSmall(localizer.draftWasDeleted());
    }
  }, [deleteExtractDraftRequest.isSuccess, localizer]);

  const getTypeOptions: DropdownOption<number>[] = useMemo(() => {
    const typeOptions = getCaseElementTypes();
    const filteredTypes = typeOptions.filter((x) => !initialFilterInput.excludedTypes.includes(x));
    return getCaseElementTypeDropdownOptions(localizer, filteredTypes);
  }, [initialFilterInput.excludedTypes, localizer]);

  useEffect(() => {
    if (caseDocuments && caseDocuments.data.length === 0 && caseDocumentsFilterInput.start > 0) {
      //Go to previous page
      setCaseDocumentsFilterInput((prev) => ({ ...prev, start: prev.start - prev.length }));
    }
  }, [caseDocuments, caseDocumentsFilterInput.start]);

  const onDocumentEditClick = (model: CaseDocumentPaginatedListModel) => {
    setEditDocumentModal(model);
  };

  const rowTypeIsExtractDraft = (row: Row<CaseDocumentPaginatedListModel>) =>
    row.original.type === CaseElementTypeEnum.ExtractDraft;

  const onEditClose = () => {
    setEditDocumentModal(undefined);
  };

  const onDocumentDelete = useCallback(
    (documentIds: string[]) => {
      getExtractDraftInfoLazyQuery({ caseId: caseId, documentIds: documentIds })
        .unwrap()
        .then((result) => {
          if (result.length === 0) {
            setDeleteDocumentsModel({
              ids: documentIds,
              message: localizer.areYouSureDocumentDelete()
            });
          } else {
            const areYouSureAttachedToExistingExtracts = `${localizer.areYouSureDocumentDelete()} ${localizer.areYouSureDocumentDeleteExtractHint()}: ${result.map((x) => x.title).join(", ")}`;

            setDeleteDocumentsModel({
              ids: documentIds,
              message: areYouSureAttachedToExistingExtracts
            });
          }
        });
    },
    [caseId, getExtractDraftInfoLazyQuery, localizer]
  );

  const getColumns = useMemo(() => {
    const renderActionMenu = (row: Row<CaseDocumentPaginatedListModel>) => {
      const addDocumentMenuItems = (
        <ExpandingDropdownMenu buttonNode={<MenuDotButton />}>
          <ExpandingDropdownMenu.ButtonItem onClick={() => onDocumentEditClick(row.original)}>
            <IconTextCallbackItem
              icon={<EmblaIcon iconName="edit" />}
              text={localizer.editDocument()}
            />
          </ExpandingDropdownMenu.ButtonItem>
          <ExpandingDropdownMenu.ButtonItem
            disabled={!canDeleteDocuments()}
            disabledText={localizer.documentDeleteNotAllowededDescription()}
            onClick={() => onDocumentDelete([row.original.id])}
          >
            <IconTextCallbackItem
              icon={<EmblaIcon iconName="delete" />}
              text={localizer.deleteDocument()}
              disabled={!canDeleteDocuments()}
            />
          </ExpandingDropdownMenu.ButtonItem>
          <ExpandingDropdownMenu.ButtonItem onClick={() => setExportDocument(row.original)}>
            <IconTextCallbackItem
              icon={<EmblaIcon iconName="document-pdf" />}
              text={localizer.exportCaseDocument()}
            />
          </ExpandingDropdownMenu.ButtonItem>
        </ExpandingDropdownMenu>
      );

      const addExtractDraftDocumentMenuItems = (
        <ExpandingDropdownMenu buttonNode={<MenuDotButton />}>
          <ExpandingDropdownMenu.ButtonItem onClick={() => setRenameExtractDraft(row.original)}>
            <IconTextCallbackItem
              icon={<EmblaIcon iconName="edit" />}
              text={localizer.renameExtractDraft()}
            />
          </ExpandingDropdownMenu.ButtonItem>
          <ExpandingDropdownMenu.ButtonItem
            disabled={!canDeleteDocuments()}
            disabledText={localizer.documentDeleteNotAllowededDescription()}
            onClick={() => setDeleteExtractDraftId(row.original.id)}
          >
            <IconTextCallbackItem
              icon={<EmblaIcon iconName="delete" />}
              text={localizer.deleteExtractDraft()}
            />
          </ExpandingDropdownMenu.ButtonItem>
        </ExpandingDropdownMenu>
      );

      return rowTypeIsExtractDraft(row) ? addExtractDraftDocumentMenuItems : addDocumentMenuItems;
    };

    const columns: Column<CaseDocumentPaginatedListModel>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.title(),
        accessor: "title",
        width: 300,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => <DocumentTitleCell {...cellProps} />
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.type(),
        accessor: "typeDisplayString",
        id: nameof<CaseDocumentPaginatedListModel>((d) => d.type), // define which column to sort on
        width: 100,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (
          cellProps: React.PropsWithChildren<CellProps<CaseDocumentPaginatedListModel, string>>
        ) =>
          rowTypeIsExtractDraft(cellProps.row) ? (
            <Badge key={cellProps.row.index} pill size="large" appearance="default">
              {" "}
              <EmblaIcon iconName={"edit"} size={IconSize.Medium} /> {cellProps.cell.value}
            </Badge>
          ) : (
            cellProps.cell.value
          )
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.numberOfPages(),
        accessor: "pageCount",
        id: nameof<CaseDocumentPaginatedListModel>((d) => d.pageCount), // define which column to sort on
        width: 100,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => (
          <TableCell {...cellProps} value={cellProps.value ?? localizer.notApplicable()} />
        )
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.uploadedBy(),
        accessor: "uploadedByName",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => (
          <TableCell {...cellProps}>
            <EmblaIcon iconName={rowTypeIsExtractDraft(cellProps.row) ? "copy" : "document"} />
            {` ${cellProps.cell.value}`}
          </TableCell>
        )
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.date(),
        accessor: (dto) =>
          dto.dateType === DateTypeEnum.UnDated
            ? localizer.noDateDateType()
            : dto.date && dateFormatter.date(dto.date, undefined, dto.dateType),
        id: nameof<CaseDocumentPaginatedListModel>((d) => d.date) // define which column to sort on
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.lastActivity(),
        accessor: (dto) => dateFormatter.timeSince(dto.lastChangedAt),
        id: nameof<CaseDocumentPaginatedListModel>((d) => d.lastChangedAt) // define which column to sort on
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: () => null,
        disableSortBy: true,
        id: "actions",
        disableClick: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (
          cellProps: React.PropsWithChildren<CellProps<CaseDocumentPaginatedListModel, string>>
        ) => renderActionMenu(cellProps.row),
        width: 40
      }
    ];

    if (multiselectDelete) {
      columns.unshift({
        width: 20,
        id: "multiselect",
        disableSortBy: true,
        disableClick: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div className="d-flex justify-content-center">
            <Checkbox {...getToggleAllRowsSelectedProps()} id="selectAll" />
          </div>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: ({
          row
        }: {
          row: Row<CaseDocumentPaginatedListModel> &
            UseRowSelectRowProps<CaseDocumentPaginatedListModel>;
        }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              row.toggleRowSelected();
            }}
            className={classNames(
              "d-flex justify-content-center align-items-center",
              styles.checkbox
            )}
          >
            <Checkbox {...row.getToggleRowSelectedProps()} id="" />
          </div>
        )
      });
    }

    return columns;
  }, [canDeleteDocuments, dateFormatter, localizer, onDocumentDelete, multiselectDelete]);

  // Filters

  const handleFilterTypeChanged = (newType?: number) => {
    const typeEnum = newType as CaseElementTypeEnum | undefined;
    setCaseDocumentsFilterInput((prev) => ({
      ...prev,
      types: typeEnum !== undefined ? [typeEnum] : initialFilterInput.types,
      start: 0
    }));
  };

  const handleFilterUploaderChanged = (newUploadedByUserId?: string) => {
    setCaseDocumentsFilterInput((prev) => ({
      ...prev,
      uploadedByUserId: newUploadedByUserId,
      start: 0
    }));
  };

  const handleMultiselectDelete = useCallback(
    (rows: Row<CaseDocumentPaginatedListModel>[]) => {
      onDocumentDelete(rows.map((r) => r.original.id));
    },
    [onDocumentDelete]
  );

  const extraFiltersHtml = (
    <>
      <div>
        <label>
          <Dropdown
            placeholder={localizer.type()}
            options={getTypeOptions}
            noOptionsMessage={() => localizer.noResultsFound()}
            onChange={(newVal) => handleFilterTypeChanged(newVal?.value)}
            isClearable={true}
            className="min-w-190"
          />
        </label>
      </div>
      <div>
        <label>
          <Dropdown
            placeholder={localizer.uploadedBy()}
            options={uploaders?.map((c) => {
              return { label: c.name, value: c.userId };
            })}
            noOptionsMessage={() => localizer.noResultsFound()}
            onChange={(newVal) => handleFilterUploaderChanged(newVal?.value)}
            isClearable={true}
            isLoading={uploadersIsLoading}
            className="min-w-190"
          />
        </label>
      </div>
    </>
  );

  return (
    <>
      <Card className="py-2">
        <Card.Header>
          <h3>{tableTitle}</h3>
        </Card.Header>
        <PaginatedTable
          handleDelete={handleMultiselectDelete}
          additionalClasses={classNames(
            styles.smallRows,
            multiselectDelete && styles.firstColumnNoPadding
          )}
          columns={getColumns}
          data={caseDocuments?.data ?? []}
          paginatedListInput={caseDocumentsFilterInput}
          setPaginatedListInput={
            setCaseDocumentsFilterInput as unknown as React.Dispatch<
              React.SetStateAction<PaginatedListInput>
            >
          }
          isLoading={caseDocumentsIsLoading}
          isFetching={caseDocumentsIsFetching}
          noResultsOptions={{
            noResultsMessage: localizer.noResultsFound()
          }}
          totalRecords={caseDocuments?.recordsFiltered ?? 0}
          rowOnClick={(document) => {
            if (document.type === CaseElementTypeEnum.ExtractDraft) {
              navigate(RoutePaths.caseExtract.url(document.caseId, document.id));
            } else {
              navigate(RoutePaths.caseDocument.url(document.caseId, document.id));
            }
          }}
          extraFiltersHtml={useExtraFilters ? extraFiltersHtml : undefined}
          hidePagination={
            !caseDocuments || caseDocuments.recordsFiltered <= caseDocumentsFilterInput.length
          }
          hidePageSizeFilter={true}
          hideSearchLabel={true}
          initialSort={initialSort}
          hideSearchFilter={hideSearchFilter}
        />
      </Card>
      <AddDocumentsModal caseId={caseId} />
      <ModalMessage
        open={!!deleteDocumentsModel}
        onClose={() => setDeleteDocumentsModel(undefined)}
        title={localizer.areYouSure()}
        description={deleteDocumentsModel?.message ?? ""} //The model will only open if deleteDocumentModel is not undefined. So this will never be null
        modalAcceptType="danger"
        acceptButtonText={localizer.delete()}
        acceptAction={() =>
          deleteDocumentsModel &&
          deleteDocument({ caseId: caseId, documentIds: deleteDocumentsModel.ids })
        }
      />
      <ModalMessage
        open={!!deleteExtractDraftId}
        onClose={() => setDeleteExtractDraftId(undefined)}
        title={localizer.areYouSure()}
        description={localizer.areYouSureExtractDraftDelete()}
        modalAcceptType="danger"
        acceptButtonText={localizer.delete()}
        acceptAction={() =>
          deleteExtractDraftId &&
          deleteExtractDraft({ caseId: caseId, extractDraftId: deleteExtractDraftId })
        }
      />
      <Modal
        open={editDocumentModal !== undefined}
        onClose={() => onEditClose()}
        size={ModalSizeEnum.Small}
      >
        {editDocumentModal !== undefined &&
          elementTypeIsCaseDocumentType(editDocumentModal.type) && (
            <EditDocument
              documentfileName={editDocumentModal.title}
              documentType={editDocumentModal.type}
              dateType={editDocumentModal.dateType}
              caseDocumentId={editDocumentModal.id}
              caseId={editDocumentModal.caseId}
              documentDate={editDocumentModal.date}
              caseDocumentNumber={editDocumentModal.caseDocumentNumber}
            />
          )}
      </Modal>
      <Modal
        open={!!addDocumentExtractDraftIds}
        onClose={() => {
          setAddDocumentExtractDraftIds(undefined);
        }}
      >
        <Modal.Header>{localizer.addDocuments()}</Modal.Header>
        <Modal.Body>
          {addDocumentExtractDraftIds && (
            <AddDocumentsToDraft
              showInfoText={false}
              caseId={caseId}
              extractDraftId={addDocumentExtractDraftIds.extractDraftId}
              singleDocumentId={addDocumentExtractDraftIds.documentIdString}
              usePortalActionsContainer={false}
              onSuccess={() => setAddDocumentExtractDraftIds(undefined)}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        onClose={() => setRenameExtractDraft(undefined)}
        open={!!renameExtractDraft}
        size={ModalSizeEnum.Small}
      >
        <Modal.Header>{localizer.renameExtractDraft()}</Modal.Header>
        <Modal.Body>
          {renameExtractDraft && (
            <UpdateExtractDraft
              caseId={renameExtractDraft.caseId}
              extractDraftId={renameExtractDraft.id}
              initialValues={{ title: renameExtractDraft.title }}
            />
          )}
        </Modal.Body>
      </Modal>
      {exportDocument && (
        <ExportCaseDocumentModal
          caseDocumentId={exportDocument.id}
          caseDocumentTitle={exportDocument.title}
          caseId={caseId}
          onCloseCallback={() => setExportDocument(undefined)}
          openModal={exportDocument !== undefined}
        />
      )}
    </>
  );
};
