import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSObjectWithLabel } from "react-select";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { memo } from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import Toolbar from "@components/toolbar/toolbar";
import MarkingOwnerFilterDropdown from "../markingFilter/markingOwnerFilterDropdown";
import MarkingTypeFilterDropdown from "../markingFilter/markingTypeFilterDropdown";
import SearchPopover from "../searchPopover/searchPopover";
import Toolbox from "../toolbox";
import DocumentStatus from "./documentStatus";

interface ToolBarProps {
  pdf?: DocumentCallback;
  title: string;
}

const DocumentToolbar = ({ title, pdf }: ToolBarProps) => {
  const { state } = useLocation();
  const { documentId } = useParams();
  const localizer = useLocalization();
  const navigate = useNavigate();

  return (
    <Toolbar
      title={title}
      titleAddon={documentId && <DocumentStatus documentId={documentId} />}
      backAction={() => {
        const backLocation = state?.backLocation ?? -1;
        navigate(backLocation);

        return "skip-back-navigation";
      }}
      centerItems={<Toolbox className="align-self-center " />}
    >
      {/* breaks content into two rows but still allows flex to break more if content does not fit  */}
      {/* <div className="d-md-none w-100" /> */}
      <div className="d-flex gap-8">
        <Toolbar.Item
          additionalClasses="p-0"
          id="pdfMarkingTypes"
          label={localizer.pdfMarkingTypes()}
        >
          <MarkingTypeFilterDropdown stylesOverride={{ control: controlStyle }} />
        </Toolbar.Item>
        <Toolbar.Item
          additionalClasses="p-0"
          id="pdfCreatedbyFilterTitle"
          label={localizer.pdfCreatedByFilterTitle()}
        >
          <MarkingOwnerFilterDropdown stylesOverride={{ control: controlStyle }} />
        </Toolbar.Item>
        <Toolbar.Item additionalClasses="p-0">
          <SearchPopover pdf={pdf} />
        </Toolbar.Item>
      </div>
    </Toolbar>
  );
};

const controlStyle = (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...provided,
  borderColor: "var(--toolbar-control-border-color)",
  color: "var(--toolbar-control-text-color)",
  borderRadius: "30px",
  border: "1px solid rgb(179, 179, 179)"
});

export default memo(DocumentToolbar);
