import { useState } from "react";
import { Document, Page } from "react-pdf";
import { ElementSize } from "src/hooks/useElementSize";

type MultiPagePdfViewProps = {
  url: string;
  containerSize: ElementSize;
  margins: number;
  pageGap: number;
  renderAnnotationLayer?: boolean;
};

export const MultiPagePdfView = ({
  url,
  containerSize,
  margins,
  pageGap,
  renderAnnotationLayer = false
}: MultiPagePdfViewProps) => {
  const [numPages, setNumPages] = useState(0);

  return (
    <Document file={url} onLoadSuccess={(doc) => setNumPages(doc.numPages)}>
      {Array.from(new Array(numPages), (el, index) => (
        <div key={`page_${index}`} style={{ marginBottom: `${pageGap}px` }}>
          <Page
            renderAnnotationLayer={renderAnnotationLayer}
            pageNumber={index + 1}
            width={containerSize.width - margins * 2}
            height={containerSize.height - margins * 2}
          />
        </div>
      ))}
    </Document>
  );
};
