import { StorageKeys } from "@infrastructure/storageKeys";
import { CaseDocumentSettingsModel } from "@infrastructure/storageModels";

export const getPdfViewerLatestPageIndex = (caseDocumentId: string): string => {
  const key = StorageKeys.caseDocumentSettingsStorage(caseDocumentId);
  const pdfViewerPageIndex = localStorage.getItem(key);
  let caseDocumentSettingsFromStorage: CaseDocumentSettingsModel = {};
  if (pdfViewerPageIndex !== null) {
    caseDocumentSettingsFromStorage = JSON.parse(pdfViewerPageIndex);
  }

  return caseDocumentSettingsFromStorage.latestPageIndex ?? "0";
};
