import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export enum IntervalExtractionType {
  AllDocument = 0,
  DefinedPages = 1,
  PagesWithVerticalLines = 2,
  NoDocuments = 3
}

export type AddDocumentsToDraftModel = {
  sectionId: string;
  documents: {
    caseDocumentId: string;
    pageIntervals: string;
    intervalExtractionType: IntervalExtractionType;
    sortOrder?: number;
  }[];
};

export type DraftDocumentCreateModel = {
  caseDocumentId: string;
  title: string;
  includePagesWithVerticalLines: boolean;
};

export type DraftDocumentCreateFormModel = {
  isSelected: boolean;
  document: DraftDocumentCreateModel;
};

export type AddDocumentsToDraftFormModel = {
  documents: DraftDocumentCreateFormModel[];
};

const draftDocumentCreateModelValidation = (localizer: Localizer) =>
  createValidationSchema<DraftDocumentCreateModel>({
    caseDocumentId: yup.string().required(),
    title: yup.string(),
    includePagesWithVerticalLines: yup.boolean()
  });

const draftDocumentCreateFormModelValidation = (localizer: Localizer) =>
  createValidationSchema<DraftDocumentCreateFormModel>({
    document: draftDocumentCreateModelValidation(localizer),
    isSelected: yup.boolean()
  });

export const addDocumentsToDraftFormModelValidation = (localizer: Localizer) =>
  createValidationSchema<AddDocumentsToDraftFormModel>({
    documents: yup.array().of(draftDocumentCreateFormModelValidation(localizer))
  });
