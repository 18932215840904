import { PDFPageProxy } from "pdfjs-dist/types/src/display/api";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";

const getPageDimensions = ({ rotate, view }: PDFPageProxy, pageOrientation: PageOrientation) => {
  const totalRotation = (rotate + pageOrientation) % 360;

  let width = view[2];
  let height = view[3];

  if (totalRotation === 90 || totalRotation === 270) {
    const tempWidth = width;
    width = height;
    height = tempWidth;
  }

  return {
    width: width,
    height: height,
    rotation: totalRotation as PageOrientation
  };
};

export default getPageDimensions;
