import * as yup from "yup";
import { IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";
import { Localizer } from "@components/localization/localizer";

export type ExtractDraftSectionDocumentUpdateModel = {
  caseDocumentId: string;
  id: string;
  title: string;
  includeMarkings: boolean;
  caseReferenceNumber?: string;
  date: string;
  dateType: DateTypeEnum;
  includeInToC: boolean;
  intervalExtractionType: IntervalExtractionType;
  pageIntervals: string;
};

// "1"; "1, 2, 3"; "1-3, 5, 7-10"; " 1 - 3 , 5 , 7 - 10 "
export const pageIntervalRegexString = "^(,?\\s*)[0-9]+(?:(?:\\s*,\\s*|\\s*-)[0-9]+)*(,?\\s*)$";

export const draftSectionDocumentUpdateValidation = (localizer: Localizer) =>
  createValidationSchema<ExtractDraftSectionDocumentUpdateModel>({
    caseReferenceNumber: yup.string().max(12, localizer.maxLength12()),
    date: yup.string().nullable(),
    id: yup.string().required(),
    includeInToC: yup.boolean(),
    includeMarkings: yup.boolean(),
    intervalExtractionType: yup
      .mixed<IntervalExtractionType>()
      .oneOf(Object.values(IntervalExtractionType) as number[])
      .required(),
    caseDocumentId: yup.string().required(),
    title: yup.string(),
    pageIntervals: yup.string().matches(RegExp(pageIntervalRegexString), 'e.g. "1-3, 20, 24-50"'),
    dateType: yup
      .mixed<DateTypeEnum>()
      .oneOf(Object.values(DateTypeEnum) as number[])
      .required()
  });
