import { useMemo } from "react";
import { object, ObjectSchema, string } from "yup";
import { useLocalization } from "@components/localization/localizationProvider";
import { Localizer } from "@components/localization/localizer";
import { CommentMarkingModel } from "../../../models/markingModel";

const useCommentValidation = () => {
  const localizer = useLocalization();
  return useMemo(() => commentValidation(localizer), [localizer]);
};

const commentValidation = (localizer: Localizer): ObjectSchema<Pick<CommentMarkingModel, "data">> =>
  object({
    data: object({
      text: string().trim().required(localizer.commentRequired())
    })
  });

export default useCommentValidation;
