import { areEqual, ListChildComponentProps } from "react-window";
import { CSSProperties, memo, useMemo } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { PageDimensionsArray } from "../../pageDimensions";
import PageRenderer from "../PageRenderer/PageRenderer";
import PageCanvas from "../canvas/pageCanvas";
import { usePdfSize } from "../../hooks/pdfSizeProvider";

export type PageListChildData = {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
  documentId: string;
  draggable?: boolean;
  disableCanvas?: boolean;
  caseId?: string;
  documentHasMarkings?: boolean;
  renderTextData?: boolean;
  getPageId?: (pageIndex: number) => string | undefined;
};

const PageList = (props: ListChildComponentProps<PageListChildData>) => {
  const pageDimensions = props.data.pdfDimensions[props.index];

  const {
    draggable = false,
    documentId,
    documentHasMarkings,
    getPageId,
    disableCanvas = true,
    renderTextData = true
  } = props.data;

  const { width: canvasWidth } = usePdfSize();

  const [, drag, preview] = useDrag(
    {
      type: "page",
      item: { documentId: props.data.documentId, index: props.index },
      canDrag: draggable,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    },
    [draggable]
  );

  preview(getEmptyImage(), { captureDraggingState: true });

  const otherGaps = 85;

  const leftMargin = useMemo(
    () =>
      Math.max(window.innerWidth / 2 - canvasWidth / 2 - pageDimensions.width / 2 - otherGaps, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasWidth, pageDimensions.width, window.innerWidth]
  );

  const listStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions.width,
    height: pageDimensions.height,
    marginTop: props.data.pageMargin,
    left: "0",
    right: "0",
    marginLeft: disableCanvas || !documentHasMarkings ? "auto" : leftMargin, // dont change to e.g (transform: translate ...). That will cause blurryness
    marginRight: "auto" // dont change to e.g (transform: translate ...). That will cause blurryness
  };

  return (
    <div ref={draggable ? drag : undefined} style={listStyle}>
      <PageRenderer
        data={props.data}
        pageIndex={props.index}
        pageDimensions={pageDimensions}
        onPageLoaded={props.data.onDocumentLoaded}
        orientation={pageDimensions.rotation}
        renderTextLayer={renderTextData}
      />
      {!disableCanvas && props.data.caseId && (
        <PageCanvas
          disableCards={!documentHasMarkings}
          caseId={props.data.caseId}
          documentId={documentId}
          pageDimensions={pageDimensions}
          pageIndex={props.index}
          scale={props.data.scale}
          documentPageId={getPageId?.(props.index)}
        />
      )}
    </div>
  );
};

export default memo(PageList, areEqual);
