import { useEffect, useState } from "react";
import { VariableSizeList } from "react-window";

type UseCorrectScrollOnZoomProps = {
  primaryList?: VariableSizeList | null;
  scale: number;
  currentScroll: number;
  visiblePages: number[];
  pageMargin: number;
};

const useCorrectScrollOnZoom = ({
  currentScroll,
  pageMargin,
  scale,
  primaryList,
  visiblePages
}: UseCorrectScrollOnZoomProps) => {
  const [previousScale, setPreviousScale] = useState(scale);

  useEffect(() => {
    if (previousScale !== scale) {
      // Calculate new scroll position after applying scale. Since page margin isn't scaled, we need to subtract the page margins from the X scroll position, calculate the new X scroll position, and then add the page margins again
      const lastVisiblePageIndex = visiblePages[visiblePages.length - 1];
      const visiblePagesCount = lastVisiblePageIndex + 1;
      const visiblePagesTotalMargin = visiblePagesCount * pageMargin;
      const currentScrollWithoutMargin = currentScroll - visiblePagesTotalMargin;
      const newScrollWithoutMargin = (currentScrollWithoutMargin / previousScale) * scale;
      const newScroll = newScrollWithoutMargin + visiblePagesTotalMargin;

      primaryList?.resetAfterIndex(0);
      primaryList?.scrollTo(newScroll);

      setPreviousScale(scale);
    }
  }, [scale, previousScale, currentScroll, visiblePages, primaryList]);
};

export default useCorrectScrollOnZoom;
