import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMostVisiblePageIndex } from "../pdfViewerSlice";

type UseSetMostVisiblePageIndexProps = {
  visibleStartIndex: number;
  visibleStopIndex: number;
};

export const useSetMostVisiblePageIndex = ({
  visibleStartIndex,
  visibleStopIndex
}: UseSetMostVisiblePageIndexProps) => {
  const dispatch = useDispatch();

  const calculateAndDispatchMostVisiblePageIndex = useCallback(() => {
    const midpoint = (visibleStartIndex + visibleStopIndex) / 2;
    const pageIndex = Math.floor(midpoint);

    dispatch(setMostVisiblePageIndex(pageIndex));
  }, [dispatch, visibleStartIndex, visibleStopIndex]);

  useEffect(() => {
    calculateAndDispatchMostVisiblePageIndex();
  }, [calculateAndDispatchMostVisiblePageIndex, visibleStartIndex, visibleStopIndex]);
};
