import { FilteredCaseDocumentModel } from "@services/api/case/models/filteredCaseDocumentModel";
import { DocumentFilterEnum } from "../DocumentFilterDropdown/DocumentFilterDropdown";

export const filterSidebarDocuments = (
  documents: FilteredCaseDocumentModel[],
  documentFilterType: DocumentFilterEnum
) => {
  const documentsCopy = [...documents];

  switch (documentFilterType) {
    case DocumentFilterEnum.Title:
      return documentsCopy.sort((a, b) => a.documentName.localeCompare(b.documentName));
    case DocumentFilterEnum.LastActivity:
      return documentsCopy.sort(
        (a, b) => new Date(b.lastUpdatedAt).getTime() - new Date(a.lastUpdatedAt).getTime()
      );
    case DocumentFilterEnum.NumberOfPages:
      return documentsCopy.sort((a, b) => b.pageCount - a.pageCount);
    case DocumentFilterEnum.Date:
      return documentsCopy.sort((a, b) => {
        return (b ? new Date(b.date).getTime() : 0) - (a.date ? new Date(a.date).getTime() : 0);
      });
    case DocumentFilterEnum.UploadedBy:
      return documentsCopy.sort((a, b) => a.createdByName.localeCompare(b.createdByName));
    default:
      return documents;
  }
};
