import { Dispatch, SetStateAction, useState } from "react";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import { Dropdown, DropdownOption } from "@components/dropdown/dropdown";
import { useLocalization } from "@components/localization/localizationProvider";

export enum DocumentFilterEnum {
  Title = 1,
  LastActivity = 2,
  NumberOfPages = 3,
  Date = 4,
  UploadedBy = 5
}

const getFilterOptions = (localizer: ReturnType<typeof useLocalization>) =>
  [
    { label: localizer.title(), value: DocumentFilterEnum.Title },
    { label: localizer.lastActivity(), value: DocumentFilterEnum.LastActivity },
    { label: localizer.numberOfPages(), value: DocumentFilterEnum.NumberOfPages },
    { label: localizer.date(), value: DocumentFilterEnum.Date },
    { label: localizer.uploadedBy(), value: DocumentFilterEnum.UploadedBy }
  ] as const;

type DocumentFilterDropdownProps = {
  setSelectedDocumentFilter: Dispatch<SetStateAction<DocumentFilterEnum>>;
};

const DocumentFilterDropdown = ({ setSelectedDocumentFilter }: DocumentFilterDropdownProps) => {
  const localizer = useLocalization();

  const [documentFilterDropdownValue, setDocumentFilterDropdownValue] = useState<
    SingleValue<DropdownOption<number>>
  >(getFilterOptions(localizer)[1]);

  const customDropdownStyles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: "20px"
    })
  };

  return (
    <Dropdown
      options={getFilterOptions(localizer)}
      minWidth={"110px"}
      onChange={(value) => {
        setDocumentFilterDropdownValue(value);
        setSelectedDocumentFilter(value?.value ?? DocumentFilterEnum.LastActivity);
      }}
      value={documentFilterDropdownValue}
      stylesOverride={customDropdownStyles}
    />
  );
};

export default DocumentFilterDropdown;
