import { skipToken } from "@reduxjs/toolkit/dist/query";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NotificationModule } from "ditmer-embla";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { createHorizontalStrength, useDndScrolling } from "react-dnd-scrolling";
import AddPageOutline from "@components/button/addPageOutline/addPageOutline";
import PresentButton from "@components/button/presentButton/presentButton";
import AddToPresentation from "@components/case/presentationPage/addToPresentation";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { Spinner } from "@components/spinner/spinner";
import {
  useReorderPresentationsPagesMutation,
  useGetCasePresentationsQuery,
  useBulkAddToPresentationsMutation
} from "@services/api/casePresentation/casePresentationApi";

import { CasePresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";
import { BulkAddToPresentations } from "@pages/pdfviewer/component/models/bulkAddToPresentations";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import { joyrideConstants } from "@components/joyrideOverlay/joyrideConstants";
import ZoomControl from "@pages/pdfviewer/component/toolbar/zoomControl";
import { useAppSelector } from "@hooks";
import { pdfViewerStateSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { ElementSize } from "src/hooks/useElementSize";
import { AddPresentations } from "@pages/case/presentations/addPresentations/addPresentations";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import Button from "@components/embla/button";
import { EmblaIcon, IconColor, IconSize } from "@components/embla/emblaIcon";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import { useAuth } from "@components/auth/authProvider";
import useShortcut from "src/hooks/useShortcut";
import { RoutePaths } from "@components/routing/routes";
import styles from "./bottomBar.module.scss";
import BottomBarTabs from "./bottomBarTabs";
import BulkAddButton from "./bulkAddButton";
import PagesContainer from "./PagesContainer/PageContainer";
import BulkAddButtons from "./BulkAddButtons/BulkAddButtons";
import PagesControl from "./PagesControl/PagesControl";
import AdditionalBottomBarPageDropZone from "./AdditionalBottomBarPageDropZone/AdditionalBottomBarPageDropZone";

interface BottomBarProps {
  activePresentation?: PresentationInfo;
  activePageId?: string | { index: number; caseDocumentId?: string };
  bulkAddState?: BulkAddToPresentations;
  onPageRemoved?: (pageId: string) => void;
  presentationMode?: boolean;
  redirectAppUrl?: string;
  pdf?: DocumentCallback;
  pdfViewSize?: ElementSize;
  onPageChanged?: (pageIndex: number) => void;
  documentId?: string;
}

const BottomBar = ({
  activePresentation,
  activePageId,
  bulkAddState,
  onPageRemoved,
  presentationMode,
  redirectAppUrl,
  pdf,
  pdfViewSize,
  onPageChanged,
  documentId
}: BottomBarProps) => {
  const localizer = useLocalization();
  const navigate = useNavigate();

  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const [isLoadingNewPage, setIsLoadingNewPage] = useState(false);
  const [isAddPresentationModalOpen, setIsAddPresentationModalOpen] = useState(false);

  const [reorderPresentationsPages, { isLoading: isReordering }] =
    useReorderPresentationsPagesMutation();
  const [bulkAddToPresentations, { isLoading: loadingAddToPresentation }] =
    useBulkAddToPresentationsMutation();

  const scrollingComponentRef = useRef<HTMLDivElement>(null);

  const linearHorizontalStrength = createHorizontalStrength(150);
  useDndScrolling(scrollingComponentRef, { horizontalStrength: linearHorizontalStrength });

  const { caseId } = useParams();
  if (!caseId) {
    throw new Error('Param "caseId" is required');
  }

  const { data: casePresentations } = useGetCasePresentationsQuery(caseId);
  const { currentData: pagesQueryData, isFetching: pagesQueryDataFetching } =
    useGetPresentationPagesQuery(activePresentation?.id ?? skipToken);
  const [pages, setPages] = useState<CasePresentationPageModel[]>([]);
  const [showAddToPresentationModal, setShowAddToPresentationModal] = useState(false);
  const {
    user: { id: userId }
  } = useAuth();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const reorder = async (droppedIndex: number) => {
    if (activePresentation) {
      const pageToUpdate = pages[droppedIndex];
      if (pageToUpdate.order !== droppedIndex) {
        await reorderPresentationsPages({
          caseId,
          presentationId: activePresentation.id,
          presentationPageId: pageToUpdate.id,
          oldIndex: pageToUpdate.order,
          newIndex: droppedIndex,
          userId
        }).unwrap();
        NotificationModule.showSuccessSmall(localizer.orderChanged());
      }
    }
  };

  useEffect(() => {
    if (pagesQueryData) {
      setPages(pagesQueryData);
    }
  }, [pagesQueryData]);

  const handleNavigateWithArrowKeys = (arrow: "left" | "right") => {
    if (activePageId) {
      const activePageIndex =
        typeof activePageId === "string"
          ? pages.findIndex((p) => p.id === activePageId)
          : activePageId.index;
      let nextPage = activePageIndex + (arrow === "left" ? -1 : 1);

      // Wrap around if out of bounds
      if (nextPage < 0) {
        nextPage = pages.length - 1;
      } else if (nextPage >= pages.length) {
        nextPage = 0;
      }

      const page = pages[nextPage];
      navigate(
        RoutePaths.casePresentationEdit.url(caseId, activePresentation?.id as string, page.id)
      );
    }
  };

  useShortcut({
    key: "ArrowLeft",
    callback: () => handleNavigateWithArrowKeys("left"),
    enabled: !!presentationMode
  });
  useShortcut({
    key: "ArrowRight",
    callback: () => handleNavigateWithArrowKeys("right"),
    enabled: !!presentationMode
  });

  const toggleBottomExpansion = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: "page",
    drop: (dropped: { documentId: string; index: number }) => {
      if (activePresentation && pages.length === 0) {
        bulkAddToPresentations({
          caseId,
          model: {
            documentId: dropped.documentId,
            presentations: [{ id: activePresentation.id }],
            pageIndexes: [dropped.index]
          }
        });
      }
    },
    canDrop: () => {
      return !loadingAddToPresentation;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  useEffect(() => {
    if (!isOver) {
      setPages((prevPages) => prevPages.filter((p) => !p.id.includes("dummy")));
    }
  }, [isOver]);

  drop(scrollingComponentRef);

  return (
    <div className={classNames("mx-2 py-2 px-3 rounded-top", styles.bottomBar)}>
      {!presentationMode && (
        <div
          className={classNames(
            "d-flex align-items-center gap-s",
            !isCollapsed && "pb-2",
            joyrideConstants.documentViewerBottombar
          )}
        >
          {!bulkAddState && <p className="m-0 subtle pr-1">{localizer.presentations()}</p>}
          {bulkAddState && <BulkAddButtons caseId={caseId} />}
          {!bulkAddState && (
            <ExpandingDropdownMenu
              menuDropdownPlacement="top-end"
              buttonNode={
                <EmblaIcon
                  additionalClasses={styles.overflowButton}
                  iconName="overflow"
                  size={IconSize.Medium}
                />
              }
            >
              <div className={"d-flex flex-column align-items-start"}>
                <Button noTheme onClick={() => setIsAddPresentationModalOpen(true)}>
                  <EmblaIcon iconName="plus" color={IconColor.DarkGray} size={IconSize.Medium} />
                  <span className="pl-2">{localizer.addPresentation()}</span>
                </Button>
                <BulkAddButton />
              </div>
            </ExpandingDropdownMenu>
          )}
          {(bulkAddState?.isPresentationsSelectable === undefined ||
            bulkAddState.isPresentationsSelectable === true) && (
            <BottomBarTabs
              redirectAppUrl={redirectAppUrl}
              casePresentations={casePresentations}
              activePresentation={activePresentation}
              bulkAddPresentations={bulkAddState?.presentations.map((p) => p.id)}
              caseId={caseId}
              toggleBottomExpansion={toggleBottomExpansion}
              isExpanded={!isCollapsed}
              documentId={documentId}
            />
          )}
          {pdfViewSize && (
            <div className="d-flex ml-auto gap-s flex-shrink-0">
              <PagesControl
                currentPage={pdfViewerState.mostVisiblePageIndex + 1}
                totalPages={pdfViewerState.totalPages}
              />
              <div className={styles.divider}></div>
              <ZoomControl
                transparent
                value={pdfViewerState.scale}
                pdf={pdf}
                pdfViewSize={pdfViewSize}
              />
            </div>
          )}
        </div>
      )}
      <AddButtonSlideInForm
        isOpen={isAddPresentationModalOpen}
        onCloseEffect={() => setIsAddPresentationModalOpen(false)}
        requireButton={false}
      >
        <AddPresentations caseId={caseId} startSortOrder={casePresentations?.length ?? 0} />
      </AddButtonSlideInForm>
      {!isCollapsed && (
        <div className="d-flex flex-1 align-items-center">
          <DndProvider backend={HTML5Backend}>
            <div
              ref={scrollingComponentRef}
              className={classNames(
                styles.thumbnailContainer,
                "flex-1 d-flex align-items-center gap-8",
                (isReordering ||
                  isLoadingNewPage ||
                  loadingAddToPresentation ||
                  pagesQueryDataFetching) &&
                  styles.opaque
              )}
            >
              {!pagesQueryData && pagesQueryDataFetching ? (
                <Spinner size="small" className="mx-2 margin-bottom-m" />
              ) : (
                <>
                  {pages?.map((page, index) => {
                    const pageTitle = page.isTitlePage
                      ? page.titlePageTitle
                      : `${page.pageIndex + 1}`;
                    return (
                      <PagesContainer
                        key={page.id}
                        setPages={setPages}
                        activePageId={activePageId}
                        activePresentation={activePresentation}
                        onPageRemoved={onPageRemoved}
                        presentationMode={presentationMode}
                        caseId={caseId}
                        page={page}
                        index={index}
                        pageTitle={pageTitle}
                        reorder={reorder}
                        setIsLoadingNewPage={setIsLoadingNewPage}
                        onPageChanged={onPageChanged}
                        documentId={documentId}
                      />
                    );
                  })}
                </>
              )}

              <div>
                <div className="d-flex flex-column align-items-center">
                  <AddPageOutline onClick={() => setShowAddToPresentationModal(true)} />
                  <p
                    title={localizer.insertTitlePage()}
                    className={classNames(
                      "small margin-top-xs margin-bottom-0 subtle ellipsisContainer",
                      styles.pageTitle
                    )}
                    style={{ width: "43px" }}
                  >
                    {localizer.insertTitlePage()}
                  </p>
                </div>
                {activePresentation && (
                  <Modal
                    className={styles.modal}
                    open={showAddToPresentationModal}
                    onClose={() => setShowAddToPresentationModal(false)}
                    size={ModalSizeEnum.Small}
                  >
                    <AddToPresentation
                      caseId={caseId}
                      presentationId={activePresentation.id}
                      presentationTitle={activePresentation.title}
                      onCancelClicked={() => setShowAddToPresentationModal(false)}
                    />
                  </Modal>
                )}
              </div>
              {pages.length > 0 && (
                <AdditionalBottomBarPageDropZone
                  setIsLoadingNewPage={setIsLoadingNewPage}
                  caseId={caseId}
                  activePresentation={activePresentation}
                />
              )}
            </div>
          </DndProvider>
          {pages.length > 0 && (
            <div className={classNames(styles.presentButtonPlacement, "margin-left-m")}>
              <PresentButton
                presentationId={activePresentation?.id as string}
                redirectAppUrl={redirectAppUrl}
                theme="default"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BottomBar;
