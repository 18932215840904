import { UserService } from "@services/userService";
import { HubConnectionManager } from "./hubConnectionManager";

export enum CaseDocumentProcessingStatusHubEventType {
  CaseDocumentProcessingStatusEvent = "CaseDocumentProcessingStatusEvent",
  CaseDocumentProcessingStatusMessageEvent = "CaseDocumentProcessingStatusMessageEvent"
}

export default class CaseDocumentProcessingStatusHubManager extends HubConnectionManager {
  endpointName: string;
  userService: UserService;
  apiUrl: string;

  constructor(endpointName: string, userService: UserService, apiUrl: string) {
    super();

    this.endpointName = endpointName;
    this.userService = userService;
    this.apiUrl = apiUrl;
  }
}
