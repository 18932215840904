import { CSSObjectWithLabel, SingleValue } from "react-select";
import { Dispatch, memo, SetStateAction, useState } from "react";
import { Dropdown, DropdownOption } from "@components/dropdown/dropdown";
import { useLocalization } from "@components/localization/localizationProvider";

export enum DocumentFilterTypeEnum {
  Extract = 1,
  HelpAppendix = 2,
  MaterialCollection = 3,
  Other = 4,
  All = 5
}

const getTypeFilterOptions = (localizer: ReturnType<typeof useLocalization>) =>
  [
    { label: localizer.extract(), value: DocumentFilterTypeEnum.Extract },
    { label: localizer.helpAppendix(), value: DocumentFilterTypeEnum.HelpAppendix },
    { label: localizer.materialCollection(), value: DocumentFilterTypeEnum.MaterialCollection },
    { label: localizer.other(), value: DocumentFilterTypeEnum.Other },
    { label: localizer.all(), value: DocumentFilterTypeEnum.All }
  ] as const;

type DocumentTypeFilterDropdownProps = {
  setSelectedDocumentType: Dispatch<SetStateAction<DocumentFilterTypeEnum>>;
};

const DocumentTypeFilterDropdown = ({
  setSelectedDocumentType
}: DocumentTypeFilterDropdownProps) => {
  const localizer = useLocalization();
  const [documentTypeDropdownValue, setDocumentTypeDropdownValue] = useState<
    SingleValue<DropdownOption<number>>
  >(getTypeFilterOptions(localizer)[4]);

  const customDropdownStyles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: "20px"
    })
  };

  return (
    <Dropdown
      options={getTypeFilterOptions(localizer)}
      minWidth={"110px"}
      onChange={(value) => {
        setDocumentTypeDropdownValue(value);
        setSelectedDocumentType(value?.value ?? DocumentFilterTypeEnum.All);
      }}
      value={documentTypeDropdownValue}
      stylesOverride={customDropdownStyles}
    />
  );
};

export default memo(DocumentTypeFilterDropdown);
