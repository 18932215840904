import { PaginatedListOutput } from "@models/paginatedList/paginatedListOutput";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { ExtractDraftInfoModel } from "@services/api/extractDraft/models/extractDraftInfoModel";
import baseApi, { apiTags } from "../baseApi";
import cacheFileDownloadUrl from "../cacheFileDownloadUrl";
import { ExportCaseDocumentModel } from "../case/models/exportCaseDocumentModel";
import { buildQueryString } from "../queryStringBuilder";
import { FilteredCaseDocumentModel } from "../case/models/filteredCaseDocumentModel";
import { DocumentPageInfoModel } from "./models/documentPageInfoModel";
import { DocumentBookmarkInfoModel } from "./models/documentBookmarkInfoModel";
import {
  DocumentSearchSortOrder,
  DocumentSearchResultModel
} from "./models/documentSearchResultModel";
import { CaseDocumentPaginatedListModel } from "./models/caseDocumentPaginatedListModel";
import { CaseUserOptionModel } from "./models/caseUserOptionModel";
import { UploadFileChunkedUriViewModel } from "./models/uploadFileChunkedUrlViewModel";
import { DownloadFileUrlViewModel } from "./models/downloadFileUrlViewModel";
import { CaseDocumentPaginatedListInput } from "./models/CaseDocumentPaginatedListInput";
import { CreateCaseDocumentModel } from "./models/createCaseDocumentModel";
import { EditCaseDocumentModel } from "./models/editCaseDocumentModel";
import { DownloadFileUrlResponseModel } from "./models/downloadFileUrlResponseModel";
import { CaseDocumentVerticalInterval } from "./models/caseDocumentVerticalInterval";
import {
  ChangeIsContrastingColorModel,
  ChangeIsCoordinateAutonomousModel,
  PageNumberCoordinatesModel,
  PageNumberFontUpdateModel
} from "./models/pageNumber";
import { RotateCaseDocumentModel } from "./models/rotateCaseDocumentModel";
import { RotatePageNumberModel } from "./models/rotatePageNumberModel";
import { DocumentPageThumbnailModel } from "./models/DocumentPageThumbnailModel";

const caseUrl = "/api/case";

const documentUrl = (caseId: string) => `${caseUrl}/${caseId}/document`;
const documentsUrl = (caseId: string, input: CaseDocumentPaginatedListInput) =>
  `${documentUrl(caseId)}/paginatedList?${buildQueryString(input, null)}`;
const documentUploadersUrl = (caseId: string) => `${documentUrl(caseId)}/uploaders`;
const getDocumentUploadUriUrl = (caseId: string) => `${documentUrl(caseId)}/uploaduri`;
const getDocumentDownloadUrlUrl = (caseId: string, documentId: string) =>
  `${documentUrl(caseId)}/${documentId}/downloadurl`;
const getDocumentUrl = (caseId: string, documentId: string) =>
  `${documentUrl(caseId)}/${documentId}`;
const editDocumentUrl = (caseId: string, documentId: string) =>
  `${documentUrl(caseId)}/${documentId}`;
const deleteDocumentUrl = (caseId: string) => `${documentUrl(caseId)}`;
const documentPagesUrl = (caseId: string, documentId: string) =>
  `${documentUrl(caseId)}/${documentId}/page`;
const documentExtractDraftInfoUrl = (caseId: string) => `${documentUrl(caseId)}/extractDrafts`;
const documentBookmarksUrl = (caseId: string, documentId: string) =>
  `${documentUrl(caseId)}/${documentId}/bookmark`;
const caseDocumentExportUrl = (caseId: string, caseDocumentId: string) =>
  `${documentUrl(caseId)}/${caseDocumentId}/export`;
const caseDocumentExportDownloadUrl = (caseId: string, caseDocumentExportId: string) =>
  `${documentUrl(caseId)}/export/${caseDocumentExportId}`;
const caseDocumentsFilteredUrl = (
  caseId: string,
  filteredType: number,
  containsVerticalLines: boolean
) =>
  `${documentUrl(caseId)}/filtered?filterType=${filteredType}&containsVerticalLines=${containsVerticalLines}`;
const caseDocumentOrientationUr = (
  caseId: string,
  caseDocumentPageId: string,
  caseDocumentId: string
) => `${documentUrl(caseId)}/${caseDocumentId}/page/${caseDocumentPageId}/orientation`;
const changePageNumberCoordinatesUrl = (caseId: string) =>
  `${documentUrl(caseId)}/pageNumberCoordinates`;
const changeIsPageNumberCoordinatesAutonomousUrl = (caseId: string) =>
  `${documentUrl(caseId)}/isAutonomousCoordinates`;
const changeIsContrastingColorUrl = (caseId: string) => `${documentUrl(caseId)}/isContrastColoring`;
const changePageNumberFontUrl = (caseId: string) => `${documentUrl(caseId)}/pageNumberFont`;
const rotatePageNumberUrl = (caseId: string) => `${documentUrl(caseId)}/orientation`;
const getDocumentPageThumbnailUrl = (caseId: string, documentId: string, pageIndex: number) =>
  `${documentUrl(caseId)}/${documentId}/page/${pageIndex}/thumbnail`;

const searchDocumentsUrl = (
  caseId: string,
  documentId: string,
  searchTerm: string,
  sortOrder: DocumentSearchSortOrder,
  markingsByUsersEmployeeId: string[],
  tags: string[]
) => {
  return `${caseUrl}/${caseId}/document/search?searchTerm=${searchTerm}&sortOrder=${sortOrder}&documentId=${documentId}
    ${markingsByUsersEmployeeId.length > 0 ? "&markingsByUsersEmployeeId=" + markingsByUsersEmployeeId.join("&markingsByUsersEmployeeId=") : ""}
    ${tags.length > 0 ? "&tags=" + tags.join("&tags=") : ""}`;
};

const documentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentVerticalIntervals: builder.query<
      CaseDocumentVerticalInterval[],
      { caseId: string; documentId: string }
    >({
      query: ({ caseId, documentId }) => ({
        url: `${documentUrl(caseId)}/verticalIntervals/${documentId}`,
        method: "GET"
      }),
      transformResponse: (response: { value: CaseDocumentVerticalInterval[] }) => {
        return response.value;
      }
    }),

    searchDocuments: builder.query<
      DocumentSearchResultModel,
      {
        caseId: string;
        documentId: string;
        searchTerm: string;
        sortOrder: DocumentSearchSortOrder;
        markingsByUsersEmployeeId: string[];
        tags: string[];
      }
    >({
      query: (model) => ({
        url: searchDocumentsUrl(
          model.caseId,
          model.documentId,
          model.searchTerm,
          model.sortOrder,
          model.markingsByUsersEmployeeId,
          model.tags
        ),
        method: "GET"
      }),
      providesTags: (result) => (result ? [apiTags.markingTag] : [])
    }),

    getFilteredDocuments: builder.query<
      FilteredCaseDocumentModel[],
      { caseId: string; filterType: number; containsVerticalLines: boolean }
    >({
      query: ({ caseId, filterType, containsVerticalLines }) => ({
        url: caseDocumentsFilteredUrl(caseId, filterType, containsVerticalLines),
        method: "GET"
      }),
      providesTags: (result) => (result ? [apiTags.caseDocument] : [])
    }),

    getCaseDocuments: builder.query<CaseDocumentModel[], { caseId: string }>({
      query: ({ caseId }) => ({
        url: documentUrl(caseId),
        method: "GET"
      }),
      providesTags: (result) => [
        apiTags.caseDocument,
        ...(result?.map((d) => ({ type: apiTags.caseDocument, id: d.id })) ?? [])
      ]
    }),

    getCaseDocument: builder.query<CaseDocumentModel, { caseId: string; documentId: string }>({
      query: ({ caseId, documentId }) => ({
        url: getDocumentUrl(caseId, documentId),
        method: "GET"
      }),
      providesTags: (result) => {
        return result
          ? [apiTags.caseDocument, { type: apiTags.caseDocument, id: result?.id }]
          : [apiTags.caseDocument];
      }
    }),

    getCaseDocumentsPaginated: builder.query<
      PaginatedListOutput<CaseDocumentPaginatedListModel>,
      { caseId: string; input: CaseDocumentPaginatedListInput }
    >({
      query: ({ caseId, input }) => ({
        url: documentsUrl(caseId, input),
        method: "GET"
      }),
      providesTags: (result) => [
        apiTags.caseDocument,
        ...(result?.data.map((d) => ({ type: apiTags.caseDocument, id: d.id })) ?? [])
      ]
    }),

    getCaseDocumentUploaders: builder.query<CaseUserOptionModel[], string>({
      query: (caseId) => documentUploadersUrl(caseId)
    }),

    createDocumentUploadUri: builder.mutation<UploadFileChunkedUriViewModel, { caseId: string }>({
      query: ({ caseId }) => getDocumentUploadUriUrl(caseId)
    }),

    getDocumentDownloadUrl: builder.query<
      DownloadFileUrlViewModel,
      { caseId: string; documentId: string }
    >({
      query: ({ caseId, documentId }) => getDocumentDownloadUrlUrl(caseId, documentId),
      ...cacheFileDownloadUrl
    }),

    exportCaseDocument: builder.mutation<
      string,
      { caseId: string; caseDocumentId: string; model: ExportCaseDocumentModel }
    >({
      query: ({ caseId, caseDocumentId, model }) => ({
        url: caseDocumentExportUrl(caseId, caseDocumentId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (result, _, { caseDocumentId }) =>
        result ? [{ type: apiTags.caseDocumentExport, caseDocumentId: caseDocumentId }] : []
    }),

    getCaseDocumentExportDownloadUrl: builder.query<
      DownloadFileUrlResponseModel,
      { caseId: string; caseDocumentExportId: string; caseDocumentId: string }
    >({
      query: ({ caseId, caseDocumentExportId }) =>
        caseDocumentExportDownloadUrl(caseId, caseDocumentExportId),
      providesTags: (result, _, { caseDocumentId }) => [
        { type: apiTags.caseDocumentExport, caseDocumentId: caseDocumentId }
      ]
    }),

    createDocument: builder.mutation<string, { caseId: string; model: CreateCaseDocumentModel }>({
      query: ({ caseId, model }) => ({
        url: documentUrl(caseId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (result, _, { caseId }) =>
        result ? [{ type: apiTags.case, id: caseId }, apiTags.caseDocument] : []
    }),

    createCaseDocument: builder.mutation<
      string,
      { caseId: string; model: CreateCaseDocumentModel }
    >({
      query: ({ caseId, model }) => ({
        url: documentUrl(caseId),
        method: "POST",
        body: model
      })
    }),

    changeIsContrastingColor: builder.mutation<
      boolean,
      { caseId: string; model: ChangeIsContrastingColorModel }
    >({
      query: ({ caseId, model }) => ({
        url: changeIsContrastingColorUrl(caseId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (result, _, { caseId }) =>
        result ? [{ type: apiTags.case, id: caseId }, apiTags.caseDocumentPage] : []
    }),

    changePageNumberFont: builder.mutation<
      boolean,
      { caseId: string; model: PageNumberFontUpdateModel }
    >({
      query: ({ caseId, model }) => ({
        url: changePageNumberFontUrl(caseId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (result, _, { caseId }) =>
        result ? [{ type: apiTags.case, id: caseId }, apiTags.caseDocumentPage] : []
    }),

    editDocument: builder.mutation<
      string,
      { caseId: string; documentId: string; model: EditCaseDocumentModel }
    >({
      query: ({ caseId, documentId, model }) => ({
        url: editDocumentUrl(caseId, documentId),
        method: "PUT",
        body: model
      }),
      onQueryStarted: async ({ caseId, documentId, model }, { dispatch, queryFulfilled }) => {
        const { undo } = dispatch(
          documentApi.util.updateQueryData(
            "getDocumentDownloadUrl",
            { caseId, documentId },
            (draft) => {
              draft.fileName = model.documentName;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          undo();
        }
      },
      invalidatesTags: (result) =>
        result ? [apiTags.casePresentationPage, apiTags.caseExtractDraft, apiTags.caseDocument] : []
    }),

    deleteDocuments: builder.mutation<boolean, { caseId: string; documentIds: string[] }>({
      query: ({ caseId, documentIds }) => ({
        url: deleteDocumentUrl(caseId) + "?ids=" + documentIds.join("&ids="),
        method: "DELETE"
      }),
      invalidatesTags: (result, _, { caseId, documentIds }) =>
        result
          ? [
              { type: apiTags.case, id: caseId },
              ...documentIds.map((id) => ({ type: apiTags.caseDocument, id })),
              apiTags.caseDocument,
              apiTags.casePresentationPdfFilesUrl,
              apiTags.casePresentation,
              apiTags.caseExtractDraft
            ]
          : []
    }),

    rotateCaseDocument: builder.mutation<
      string,
      {
        caseId: string;
        caseDocumentPageId: string;
        caseDocumentId: string;
        model: RotateCaseDocumentModel;
      }
    >({
      query: ({ caseId, caseDocumentPageId, caseDocumentId, model }) => ({
        url: `${caseDocumentOrientationUr(caseId, caseDocumentPageId, caseDocumentId)}`,
        method: "PUT",
        body: model
      }),
      invalidatesTags: (result, err, { caseId }) =>
        !err ? [{ type: apiTags.case, id: caseId }, apiTags.caseDocumentPage] : []
    }),

    getDocumentPages: builder.query<
      DocumentPageInfoModel[],
      { caseId: string; documentId: string }
    >({
      query: ({ caseId, documentId }) => documentPagesUrl(caseId, documentId),
      providesTags: (result) => [
        apiTags.caseDocumentPage,
        ...(result?.map((d) => ({ type: apiTags.caseDocumentPage, id: d.id })) ?? [])
      ]
    }),
    getCaseDocumentsExtractDraftInfo: builder.query<
      ExtractDraftInfoModel[],
      { caseId: string; documentIds: string[] }
    >({
      query: ({ caseId, documentIds }) =>
        documentExtractDraftInfoUrl(caseId) +
        "?caseDocumentIds=" +
        documentIds.join("&caseDocumentIds="),
      providesTags: (result) => [apiTags.caseExtractDraft]
    }),
    getDocumentBookmarks: builder.query<
      DocumentBookmarkInfoModel[],
      { caseId: string; documentId: string }
    >({
      query: ({ caseId, documentId }) => documentBookmarksUrl(caseId, documentId),
      providesTags: (result) => [
        apiTags.caseDocumentBookmark,
        ...(result?.map((d) => ({ type: apiTags.caseDocumentBookmark, id: d.id })) ?? [])
      ]
    }),
    changePageNumberCoordinates: builder.mutation<
      boolean,
      { caseId: string; skipRefetch: boolean | undefined; model: PageNumberCoordinatesModel }
    >({
      query: ({ caseId, model }) => ({
        url: changePageNumberCoordinatesUrl(caseId),
        method: "PUT",
        body: model
      }),
      onQueryStarted: async ({ caseId, model }, { dispatch, queryFulfilled }) => {
        const { undo } = dispatch(
          documentApi.util.updateQueryData(
            "getDocumentPages",
            { caseId, documentId: model.caseDocumentId },
            (draft) => {
              const pageData = draft.find((p) => p.id === model.caseDocumentPageId);
              if (pageData?.pageNumber) {
                pageData.pageNumber.x = model.x;
                pageData.pageNumber.y = model.y;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          undo();
        }
      },
      invalidatesTags: (result, _, { skipRefetch = false }) =>
        result && !skipRefetch ? [{ type: apiTags.caseDocumentPage }] : []
    }),
    rotatePageNumber: builder.mutation<boolean, { caseId: string; model: RotatePageNumberModel }>({
      query: ({ caseId, model }) => ({
        url: rotatePageNumberUrl(caseId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (result, _, { caseId }) =>
        result ? [{ type: apiTags.case, id: caseId }, apiTags.caseDocumentPage] : []
    }),
    changeIsPageNumberCoordinatesAutonomous: builder.mutation<
      boolean,
      { caseId: string; model: ChangeIsCoordinateAutonomousModel }
    >({
      query: ({ caseId, model }) => ({
        url: changeIsPageNumberCoordinatesAutonomousUrl(caseId),
        method: "PUT",
        body: model
      }),
      onQueryStarted: async ({ caseId, model }, { dispatch, queryFulfilled }) => {
        const { undo } = dispatch(
          documentApi.util.updateQueryData(
            "getDocumentPages",
            { caseId, documentId: model.caseDocumentId },
            (draft) => {
              const pageData = draft.find((p) => p.pageNumber?.number === model.number);
              if (pageData?.pageNumber) {
                pageData.pageNumber.isAutonomousCoordinates = model.isAutonomousCoordinates;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          undo();
        }
      },
      invalidatesTags: (result, _) => (result ? [{ type: apiTags.caseDocumentPage }] : [])
    }),

    getDocumentPageThumbnailUrl: builder.query<
      string | undefined,
      { caseId: string; documentId: string; pageIndex: number }
    >({
      query: ({ caseId, documentId, pageIndex }) => ({
        url: getDocumentPageThumbnailUrl(caseId, documentId, pageIndex),
        method: "GET"
      }), //We do not invalidate the thumbnail cache because the thumbnail file would never change anyways.
      transformResponse: (original: DocumentPageThumbnailModel) => {
        if (!original.thumbnailUrl) {
          return original.thumbnailUrl;
        }

        return fetch(original.thumbnailUrl)
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob));
      },
      onCacheEntryAdded: async (
        _: unknown,
        api: {
          cacheDataLoaded: Promise<{ data: string | undefined }>;
          cacheEntryRemoved: Promise<void>;
        }
      ) => {
        const cachedString = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        if (cachedString.data) {
          URL.revokeObjectURL(cachedString.data);
        }
      }
    })
  })
});

export const useChangePageNumberCoordinatesMutation = (documentId: string) => {
  // use fixedCacheKey to share progress state across components (i.e. show document save status)
  return documentApi.useChangePageNumberCoordinatesMutation({
    fixedCacheKey: `CacheChangePageNumberCoordinatesEvent:${documentId}`
  });
};

export default documentApi;
export const {
  useSearchDocumentsQuery,
  useGetCaseDocumentsPaginatedQuery,
  useGetCaseDocumentQuery,
  useLazyGetCaseDocumentQuery,
  useGetCaseDocumentUploadersQuery,
  useCreateDocumentUploadUriMutation,
  useGetDocumentDownloadUrlQuery,
  useCreateDocumentMutation,
  useCreateCaseDocumentMutation,
  useEditDocumentMutation,
  useDeleteDocumentsMutation,
  useGetDocumentPagesQuery,
  useGetDocumentBookmarksQuery,
  useGetCaseDocumentsQuery,
  useLazyGetCaseDocumentsExtractDraftInfoQuery,
  useExportCaseDocumentMutation,
  useGetCaseDocumentExportDownloadUrlQuery,
  useGetFilteredDocumentsQuery,
  useGetDocumentVerticalIntervalsQuery,
  useChangeIsPageNumberCoordinatesAutonomousMutation,
  useRotateCaseDocumentMutation,
  useRotatePageNumberMutation,
  useChangeIsContrastingColorMutation,
  useChangePageNumberFontMutation,
  useGetDocumentPageThumbnailUrlQuery
} = documentApi;
export const { resetApiState } = documentApi.util;
