import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";

export type CaseDocumentPaginatedListModel = {
  id: string;
  caseId: string;
  pageCount: number | null;
  title: string;
  type: CaseElementTypeEnum;
  dateType: DateTypeEnum;
  typeDisplayString: string;
  uploadedById: string;
  uploadedByName: string;
  uploadedAt: string;
  date?: string;
  lastChangedAt: string;
  caseDocumentNumber?: string;
  processingStatus?: CaseDocumentProcessingStatusModel;
};

export interface CaseDocumentProcessingStatusModel {
  id: string;
  caseDocumentId: string;
  ocrProcessing: CaseDocumentProcessingStatus;
  indexing: CaseDocumentProcessingStatus;
  caseDocumentProcessing: CaseDocumentProcessingStatus;
  thumbnailGeneration: CaseDocumentProcessingStatus;
}

export enum CaseDocumentProcessingStatus {
  NotStarted = 0,
  Running = 1,
  Finished = 2,
  Failed = 3
}
