import { CellProps } from "react-table";
import { useEffect, useState } from "react";
import {
  CaseDocumentPaginatedListModel,
  CaseDocumentProcessingStatus,
  CaseDocumentProcessingStatusModel
} from "@services/api/document/models/caseDocumentPaginatedListModel";
import { TableIconSpinnerCell } from "@components/table/TableIconSpinnerCell";
import useHubConnection from "src/hooks/useHubConnection";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { CaseDocumentProcessingStatusHubEventType } from "@services/signalRClient/CaseDocumentProcessingStatusHubManager";
import { CaseDocumentProcessingStatusEventModel } from "@services/signalRClient/models/CaseDocumentProcessingStatusEvenetModel";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  getCombinedDocumentProcessingStatus,
  getDocumentProcessingStatusIcon,
  getDocumentProcessingStatusLoading,
  getDocumentProcessingStatusTooltipText
} from "./utils/caseDocumentProcessingUtils";

export const DocumentTitleCell = (cellProps: CellProps<CaseDocumentPaginatedListModel, string>) => {
  const localizer = useLocalization();
  const [hubConnectionManager] = useState(
    useHubConnection(HubConnectionType.CaseDocumentProcessingStatus)
  );
  const [processingStatus, setProcessingStatus] = useState<
    CaseDocumentProcessingStatusModel | undefined
  >(cellProps.row.original.processingStatus);

  const processingStatusId = cellProps.row.original.processingStatus?.id;
  const combinedStatus = getCombinedDocumentProcessingStatus(
    cellProps.row.original.processingStatus
  );

  useEffect(() => {
    const updateStatus = async (statusId: string) => {
      await hubConnectionManager.startConnection(statusId);

      hubConnectionManager.on(
        statusId,
        CaseDocumentProcessingStatusHubEventType.CaseDocumentProcessingStatusEvent,
        (status: CaseDocumentProcessingStatusEventModel) => {
          setProcessingStatus(status);
        }
      );
    };

    if (
      combinedStatus !== CaseDocumentProcessingStatus.Finished &&
      processingStatusId !== undefined
    ) {
      updateStatus(processingStatusId);
    }

    return () => {
      hubConnectionManager.stopConnection(processingStatusId ?? "");
    };
  }, [combinedStatus, processingStatusId, hubConnectionManager]);

  return (
    <TableIconSpinnerCell
      {...cellProps}
      value={cellProps.value}
      isLoading={getDocumentProcessingStatusLoading(processingStatus)}
      iconName={getDocumentProcessingStatusIcon(processingStatus)}
      tooltipText={getDocumentProcessingStatusTooltipText(localizer, processingStatus)}
    />
  );
};
