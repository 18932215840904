import classNames from "classnames";
import { memo } from "react";
import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useUpdateSectionMutation } from "@services/api/extractDraft/extractDraftApi";
import { DocumentSortType } from "@services/api/extractDraft/models/extractDraftSectionModel";
import {
  ExtractDraftSectionUpdateModel,
  updateSectionModelValidation
} from "@services/api/extractDraft/models/extractDraftSectionUpdateModel";
import styles from "./EditableSectionName.module.scss";

type EditableSectionNameProps = {
  sectionTitle: string;
  sectionId: string;
  extractDraftId: string;
  caseId: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  handleSortClick: (sortType: DocumentSortType) => void;
  documentsSortType: DocumentSortType;
};

const EditableSectionName = ({
  sectionId,
  sectionTitle,
  extractDraftId,
  caseId,
  isEditing,
  setIsEditing,
  handleSortClick,
  documentsSortType
}: EditableSectionNameProps) => {
  const localizer = useLocalization();

  const [updateSection] = useUpdateSectionMutation();

  const methods = useValidatedForm({
    validationSchema: updateSectionModelValidation(localizer),
    defaultValues: { title: sectionTitle }
  });

  const handleUpdateSectionName = async (model: ExtractDraftSectionUpdateModel) => {
    await updateSection({
      caseId,
      extractDraftId,
      sectionId,
      model
    });
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <FormInput
          type="text"
          methods={methods}
          name="title"
          id={`${sectionId}-title`}
          formGroupClassName="col-3"
          autoFocus
          onBlur={() => methods.handleSubmit(handleUpdateSectionName)()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              methods.handleSubmit(handleUpdateSectionName)();
            }
          }}
        />
      ) : (
        <div className="col-3 d-flex gap-s m-0 align-items-center">
          <h4
            title={sectionTitle}
            className={classNames(styles.cursorPointer, styles.sectionTitle)}
            onClick={() => handleSortClick(DocumentSortType.Alphabetical)}
          >
            {sectionTitle}
          </h4>
          <div
            className={
              documentsSortType === DocumentSortType.Alphabetical
                ? styles.sortDesc
                : styles.sortIcon
            }
          ></div>
        </div>
      )}
    </>
  );
};

export default memo(EditableSectionName);
