import classNames from "classnames";
import { PillBadge } from "@components/badge/pillBadge";
import { BadgeSize, Color } from "src/utility/constants";
import styles from "./participantBadge.module.scss";

interface ParticipantBadgeProps {
  name: string;
  initials?: string;
  profilePictureUrl?: string;
  badgeContainerClassNames?: string;
  nameFieldClassNames?: string;
  hideNameField?: boolean;
  size?: BadgeSize;
  pill?: boolean;
}

export const ParticipantBadge: React.FC<ParticipantBadgeProps> = ({
  name,
  profilePictureUrl,
  badgeContainerClassNames,
  nameFieldClassNames,
  hideNameField,
  size = BadgeSize.Small,
  initials
}: ParticipantBadgeProps) => {
  const getInitials = () => {
    const nameParts = name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    const firstNameInitial = (firstName.charAt(0) + firstName.charAt(1)).toUpperCase();
    if (nameParts.length === 1) {
      return firstNameInitial;
    }

    const lastNameInitial = (lastName.charAt(0) + lastName.charAt(1)).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  };

  const formattedInitials = initials || getInitials();

  return (
    <>
      <div className={classNames(styles.participantBadge, badgeContainerClassNames)}>
        {profilePictureUrl ? (
          <img
            className={classNames(styles.participantBadge, styles.profilePicture)}
            alt={"profilepicture"}
            src={profilePictureUrl}
          />
        ) : (
          <PillBadge size={size} title={name} color={Color.Secondary}>
            {formattedInitials}
          </PillBadge>
        )}
      </div>
      {!hideNameField && (
        <div
          className={classNames(styles.nameField, "margin-left-s", nameFieldClassNames)}
          title={name}
        >
          <div>{name}</div>
        </div>
      )}
    </>
  );
};
