import classNames from "classnames";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Card from "@components/embla/card";
import { useGetFilteredDocumentsQuery } from "@services/api/document/caseDocumentApi";
import Button from "@components/embla/button";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { useDeleteDocumentFromSectionExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { Tooltip } from "@components/tooltip/tooltip";
import { useIsKeyDown } from "src/hooks/useIsKeyDown";
import { ModalSizeEnum } from "@components/modal/modal";
import { dndItemTypes } from "../ExtractSections/DndItemTypes";
import styles from "./extractContentSidebar.module.scss";
import Document from "./document/Document";
import { AddDocumentsModal } from "./addDocumentsModal/addDocumentsModal";
import DocumentTypeFilterDropdown, {
  DocumentFilterTypeEnum
} from "./DocumentTypeFilterDropdown/DocumentTypeFilterDropdown";
import DocumentFilterDropdown, {
  DocumentFilterEnum
} from "./DocumentFilterDropdown/DocumentFilterDropdown";
import { filterSidebarDocuments } from "./utils/sidebarUtils";

export type ExtractContentSidebarProps = {
  additionalClasses?: string;
  caseId: string;
  setOnlySelectVerticalLines: (value: boolean) => void;
  onlySelectVerticalLines: boolean;
  extractDraft: ExtractDraftModel;
};

export const ExtractContentSidebar = ({
  additionalClasses,
  caseId,
  onlySelectVerticalLines,
  setOnlySelectVerticalLines,
  extractDraft
}: ExtractContentSidebarProps) => {
  const localizer = useLocalization();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState(DocumentFilterTypeEnum.All);
  const [documentFilterType, setDocumentFilterType] = useState(DocumentFilterEnum.LastActivity);

  const [lastSelectedIndex, setLastSelectedIndex] = useState(0);

  const { data: documents, refetch } = useGetFilteredDocumentsQuery({
    caseId,
    filterType: selectedDocumentType,
    containsVerticalLines: onlySelectVerticalLines
  });
  const [removeDocument] = useDeleteDocumentFromSectionExtractDraftMutation();

  const { selectedDocumentsReducer } = useExtractCompositionContext();

  const unusedDocuments = useMemo(() => {
    if (!documents) {
      return [];
    }
    const usedDocuments = extractDraft.sections.flatMap((section) =>
      section.documents.map((doc) => doc.caseDocumentId)
    );
    if (extractDraft.frontpage?.caseDocumentId) {
      usedDocuments.push(extractDraft.frontpage.caseDocumentId);
    }
    return documents.filter((doc) => !usedDocuments.includes(doc.id));
  }, [extractDraft, documents]);

  const filteredDocuments = useMemo(
    () => filterSidebarDocuments(unusedDocuments, documentFilterType),
    [documentFilterType, unusedDocuments]
  );

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOnlySelectVerticalLines(e.target.checked);
    selectedDocumentsReducer.dispatch({ selectedDocuments: [] });
  };

  const [{ isOver: isDocumentHoveringOver, draggedItem }, dropDocument] = useDrop({
    accept: dndItemTypes.document,
    drop: (document: ReorderExtractDraftSectionDocument) => {
      if (!document.sectionId) {
        return;
      }
      removeDocument({
        documentId: document.document.id,
        sectionId: document.sectionId,
        caseId,
        extractDraftId: extractDraft.id,
        shouldInvalidate: false
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      draggedItem: monitor.getItem()
    })
  });

  const { isKeyDown: isControlDown } = useIsKeyDown("Control");
  const { isKeyDown: isShiftDown } = useIsKeyDown("Shift");

  return (
    <>
      <Card className={classNames(styles.sidebarWrapper, additionalClasses)}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="card-header d-flex justify-content-between flex-column pb-0">
            <div className="d-flex align-items-center justify-content-between w-100 pb-1">
              <h4 className="card-title margin-right-m">{localizer.documents()}</h4>
              <div className="d-flex gap-s">
                <DocumentFilterDropdown setSelectedDocumentFilter={setDocumentFilterType} />
                <DocumentTypeFilterDropdown setSelectedDocumentType={setSelectedDocumentType} />
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 align-items-center pb-2 pt-1">
              <div className="d-flex">
                <InlineSwitch onChange={handleSwitchChange} checked={onlySelectVerticalLines} />
                <p className="m-0 ml-2">{localizer.onlyIncludeVerticalLines()}</p>
              </div>
              <Tooltip message={localizer.verticalLinesInfoButton()}>
                <span>
                  <EmblaIcon iconName="info" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div
            ref={dropDocument}
            className={classNames(styles.sidebarScroll, "d-flex flex-column align-items-start", {
              [styles.documentHover]: isDocumentHoveringOver && draggedItem.sectionId
            })}
          >
            {filteredDocuments?.map((doc, index) => (
              <Document
                allUnusedDocuments={unusedDocuments}
                key={doc.id}
                document={doc}
                index={index}
                setLastSelectedIndex={setLastSelectedIndex}
                isCtrlPressed={isControlDown}
                isShiftPressed={isShiftDown}
                lastSelectedIndex={lastSelectedIndex}
              />
            ))}
          </div>
          <div className={styles.addDocumentsButtonContainer}>
            <Button className="fw-bold" onClick={toggleModal} inverted>
              <EmblaIcon iconName="plus" />
              {localizer.addDocuments()}
            </Button>
          </div>
        </div>
      </Card>
      <AddDocumentsModal
        modalSize={ModalSizeEnum.Large}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        caseId={caseId}
        onClose={refetch}
      />
    </>
  );
};
