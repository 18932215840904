import classNames from "classnames";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import { CSSProperties, MouseEvent, useCallback, useMemo, useState } from "react";
import { Page } from "react-pdf";
import { PageCallback } from "react-pdf/dist/cjs/shared/types";
import {
  bulkAddPageRange,
  bulkAddTogglePage,
  pdfViewerStateSelector,
  setGoToIndex
} from "@pages/pdfviewer/component/pdfViewerSlice";
import Checkbox from "@components/checkbox/checkbox";
import { buildSafeRegExp } from "src/utility/regExp";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { useAppDispatch, useAppSelector } from "@hooks";
import { PdfToolType } from "../../models/pdfTool";
import { PageDimensions, PageDimensionsArray } from "../../pageDimensions";

import ThumbnailOverlay from "../../thumbnail/thumbnailOverlay/thumbnailOverlay";
import { TempMarkingAction } from "../../models/tempMarkingAction";
import { PageLoader } from "../pageLoader";
import useHighlightTool from "../useHighlightTool";
import usePageThumbnailIcons from "../usePageThumbnailIcons";
import styles from "./PageRenderer.module.scss";
import { highlightText } from "./util";

export type PageListChildData = {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale?: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
};

type PageRendererProps = {
  data: Partial<PageListChildData>;
  pageIndex: number;
  pdfPageIndex?: number; // when pdf page index is different from document page (i.e. presentation slit into single pages)
  pageDimensions: PageDimensions;
  orientation?: PageOrientation;
  style?: CSSProperties;
  className?: string;
  onRenderSuccess?: (page: PageCallback) => void;
  onRenderError?: (error: Error) => void;
  onTempMarkingAction?: (a: TempMarkingAction) => void;
  thumbnailMargin?: number;
  onPageLoaded?: () => void;
  leftMargin?: number;
  renderTextLayer?: boolean;
  overlay?: React.ReactNode;
};

const PageRenderer = ({
  data,
  pageDimensions,
  pageIndex,
  thumbnailMargin,
  className,
  onPageLoaded,
  onRenderError,
  onRenderSuccess,
  onTempMarkingAction,
  orientation,
  pdfPageIndex,
  style,
  renderTextLayer = true,
  overlay
}: PageRendererProps) => {
  const [textItems, setTextItems] = useState<TextItem[]>([]);

  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const dispatch = useAppDispatch();

  const { thumbnailOverlayIcons } = usePageThumbnailIcons(pageIndex, !data.isThumbnail);

  const { setPdfContainerRef, highlightActive } = useHighlightTool({
    pageIndex: pageIndex,
    disabled: data.isThumbnail,
    scale: data.scale,
    onTempMarkingAction: onTempMarkingAction
  });

  const onPageLoadSuccess = useCallback(
    async (page: PageCallback) => {
      if (!renderTextLayer) {
        return;
      }
      const textContent = await page.getTextContent();
      setTextItems(textContent.items as TextItem[]);
    },
    [renderTextLayer]
  );

  // based on https://github.com/wojtekmaj/react-pdf/issues/614
  const textRenderer = useCallback(
    (
      layer: {
        pageIndex: number;
        pageNumber: number;
        itemIndex: number;
      } & TextItem
    ) => {
      const getTextItemWithNeighbors = (itemIndex: number, span = 2) => {
        return textItems
          .slice(Math.max(0, itemIndex - span), itemIndex + 1 + span)
          .filter(Boolean)
          .map((item) => item.str)
          .join(" ")
          .replace(/  +/g, " ");
      };

      const getIndexRange = (string: string, pattern: RegExp) => {
        const match = pattern.exec(string);

        if (match) {
          return { start: match.index, end: match.index + match[0].length };
        }
      };

      const matchAcrossNeighbors = (
        text: string,
        hitText: string,
        itemIndex: number
      ): RegExp | null => {
        const pattern = buildSafeRegExp(hitText, "i"); // match case-insensitive

        const matches = text.match(pattern);

        if (matches) {
          return pattern;
        }

        const textItemWithNeighbors = getTextItemWithNeighbors(itemIndex);

        const matchInNeighbors = getIndexRange(textItemWithNeighbors, pattern);

        if (!matchInNeighbors) {
          // No match
          return null;
        }

        const itemInNeighbors = getIndexRange(textItemWithNeighbors, buildSafeRegExp(text));

        // exact match
        if (
          !itemInNeighbors ||
          matchInNeighbors.end < itemInNeighbors?.start ||
          matchInNeighbors.start > itemInNeighbors?.end
        ) {
          return null;
        }
        // Match found was partially in the line we're currently rendering. Now
        // we need to figure out what does "partially" exactly mean

        // Find partial match in a line
        const indexOfCurrentTextItemInMergedLines = textItemWithNeighbors.indexOf(text);

        const matchIndexStartInTextItem = Math.max(
          0,
          matchInNeighbors.start - indexOfCurrentTextItemInMergedLines
        );

        const matchIndexEndInTextItem = matchInNeighbors.end - indexOfCurrentTextItemInMergedLines;

        const partialStringToHighlight = text.slice(
          matchIndexStartInTextItem,
          matchIndexEndInTextItem
        );

        return buildSafeRegExp(partialStringToHighlight);
      };

      const text = layer.str;

      const hitsOnCurrentPage = pdfViewerState.searchHits?.filter((t) => t.pageIndex === pageIndex);

      if (pdfViewerState.searchHits && hitsOnCurrentPage && hitsOnCurrentPage.length > 0) {
        const matchesToHighlight = hitsOnCurrentPage
          .map((element) => matchAcrossNeighbors(text, element.hitText, layer.itemIndex))
          .filter((t): t is RegExp => Boolean(t));

        return highlightText(text, matchesToHighlight);
      } else {
        return text;
      }
    },
    [pdfViewerState.searchHits, pageIndex, textItems]
  );

  const pageIsVisible = useMemo(
    () => !data.visiblePages || data.visiblePages.includes(pageIndex),
    [data.visiblePages, pageIndex]
  );

  const pageClicked = useCallback(
    (e: MouseEvent) => {
      if (data.isThumbnail) {
        if (pdfViewerState.bulkAdd) {
          if (e.shiftKey) {
            dispatch(bulkAddPageRange(pageIndex));
          } else {
            dispatch(bulkAddTogglePage(pageIndex));
          }
        } else {
          dispatch(setGoToIndex(pageIndex));
        }
      }
    },
    [data.isThumbnail, dispatch, pageIndex, pdfViewerState.bulkAdd]
  );

  const shouldRender = useMemo(
    () => (): boolean => {
      const maxPageIndex = pdfViewerState.totalPages - 1;
      return (pdfPageIndex ?? pageIndex) <= maxPageIndex;
    },
    [pageIndex, pdfPageIndex, pdfViewerState.totalPages]
  );

  return !shouldRender() ? null : ( // don't render pages for empty grid columns
    <div
      role={data.isThumbnail ? "button" : undefined}
      onClick={pageClicked}
      className={classNames(styles.pageContainer, className)}
      style={style}
    >
      {!pageIsVisible ? (
        <PageLoader
          style={{ width: pageDimensions.width, height: pageDimensions.height }}
          className={data.getPageClassName?.(pageIndex)}
        />
      ) : (
        <>
          <div className="relative">
            {data.isThumbnail && <ThumbnailOverlay iconTypes={thumbnailOverlayIcons} />}
            {overlay && <div className={styles.overlay}>{overlay}</div>}
            <Page
              loading={
                <PageLoader
                  style={{ width: pageDimensions.width, height: pageDimensions.height }}
                  className={data.getPageClassName?.(pageIndex)}
                />
              }
              {...pageDimensions}
              inputRef={setPdfContainerRef}
              className={classNames(
                styles.page,
                {
                  [styles.annotationsActive]:
                    pdfViewerState.activeTool.type === PdfToolType.SelectMarking,
                  [styles.disableDefaultSelection]: highlightActive
                },
                data.getPageClassName?.(pageIndex)
              )}
              pageIndex={pdfPageIndex ?? pageIndex}
              onLoadSuccess={(pageCallback) => {
                onPageLoadSuccess(pageCallback);
                onPageLoaded?.();
              }}
              onRenderSuccess={onRenderSuccess}
              onRenderError={onRenderError}
              rotate={orientation}
              renderTextLayer={!data.isThumbnail || renderTextLayer}
              renderAnnotationLayer={data.renderAnnotationLayer ?? false}
              customTextRenderer={textRenderer}
              error={<div>Error</div>}
            />

            {data.isThumbnail && pdfViewerState.bulkAdd && (
              <div className={styles.bulkAddCheckbox}>
                <Checkbox
                  id={`bulk-add-page-${pageIndex}`}
                  checked={pdfViewerState.bulkAdd.pageIndexes.includes(pageIndex)}
                  readOnly
                  onClick={pageClicked}
                />
              </div>
            )}
          </div>

          {data.isThumbnail && (
            <div
              className={"d-flex justify-content-center margin-top-s"}
              style={{ marginTop: thumbnailMargin ? `${thumbnailMargin}px` : undefined }}
            >
              {pageIndex + 1}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PageRenderer;
