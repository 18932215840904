import { StorageKeys } from "@infrastructure/storageKeys";
import { CaseDocumentSettingsModel } from "@infrastructure/storageModels";

export const saveCaseDocumentSettings = (caseDocumentId: string, latestPageIndex: string) => {
  const key = StorageKeys.caseDocumentSettingsStorage(caseDocumentId);
  let newCaseDocumentSettings: CaseDocumentSettingsModel = {};

  const caseSettings = localStorage.getItem(key);
  if (caseSettings !== null) {
    const caseDocumentSettingsFromStorage: CaseDocumentSettingsModel = JSON.parse(caseSettings);
    newCaseDocumentSettings = {
      ...caseDocumentSettingsFromStorage,
      latestPageIndex: latestPageIndex
    };
  } else {
    newCaseDocumentSettings = { latestPageIndex: latestPageIndex };
  }
  localStorage.setItem(key, JSON.stringify(newCaseDocumentSettings));
};
