import { Localizer } from "@components/localization/localizer";
import {
  CaseDocumentProcessingStatus,
  CaseDocumentProcessingStatusModel
} from "@services/api/document/models/caseDocumentPaginatedListModel";

export const getCombinedDocumentProcessingStatus = (status?: CaseDocumentProcessingStatusModel) => {
  if (status === undefined || status === null) {
    return undefined;
  }

  const statuses = [
    status.ocrProcessing,
    status.indexing,
    status.caseDocumentProcessing,
    status.thumbnailGeneration
  ];

  if (statuses.every((state) => state === CaseDocumentProcessingStatus.Finished)) {
    return CaseDocumentProcessingStatus.Finished;
  }

  if (statuses.every((state) => state === CaseDocumentProcessingStatus.NotStarted)) {
    return CaseDocumentProcessingStatus.NotStarted;
  }

  if (statuses.some((state) => state === CaseDocumentProcessingStatus.Failed)) {
    return CaseDocumentProcessingStatus.Failed;
  }

  return CaseDocumentProcessingStatus.Running;
};

export const getDocumentProcessingStatusLoading = (status?: CaseDocumentProcessingStatusModel) => {
  const combinedStatus = getCombinedDocumentProcessingStatus(status);

  return combinedStatus === CaseDocumentProcessingStatus.Running;
};

export const getDocumentProcessingStatusIcon = (status?: CaseDocumentProcessingStatusModel) => {
  const combinedStatus = getCombinedDocumentProcessingStatus(status);

  switch (combinedStatus) {
    case CaseDocumentProcessingStatus.Finished:
      return "document";
    case CaseDocumentProcessingStatus.Failed:
      return "remove";
    case CaseDocumentProcessingStatus.NotStarted:
      return "required";
    default:
      return "copy";
  }
};

export const getDocumentProcessingStatusTooltipText = (
  localizer: Localizer,
  status?: CaseDocumentProcessingStatusModel
) => {
  const combinedStatus = getCombinedDocumentProcessingStatus(status);

  switch (combinedStatus) {
    case CaseDocumentProcessingStatus.Running:
      if (status?.ocrProcessing === CaseDocumentProcessingStatus.Running) {
        return localizer.ocrProcessingRunning();
      } else if (status?.indexing === CaseDocumentProcessingStatus.Running) {
        return localizer.indexingRunning();
      } else if (status?.caseDocumentProcessing === CaseDocumentProcessingStatus.Running) {
        return localizer.caseDocumentProcessingRunning();
      } else if (status?.thumbnailGeneration === CaseDocumentProcessingStatus.Running) {
        return localizer.thumbnailGenerationRunning();
      }

      return localizer.documentProcessingRunning();
    case CaseDocumentProcessingStatus.Failed:
      if (status?.ocrProcessing === CaseDocumentProcessingStatus.Failed) {
        return localizer.ocrProcessingFailed();
      } else if (status?.indexing === CaseDocumentProcessingStatus.Failed) {
        return localizer.indexingFailed();
      } else if (status?.caseDocumentProcessing === CaseDocumentProcessingStatus.Failed) {
        return localizer.caseDocumentProcessingFailed();
      } else if (status?.thumbnailGeneration === CaseDocumentProcessingStatus.Failed) {
        return localizer.thumbnailGenerationFailed();
      }
      return localizer.documentProcessingFailed();
    case CaseDocumentProcessingStatus.NotStarted:
      return localizer.documentProcessingNotStarted();
    default:
      return undefined;
  }
};
