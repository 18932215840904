import classNames from "classnames";
import { Menu } from "@headlessui/react";
import React, { MouseEvent, ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import ReactDOM from "react-dom";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./dropdownMenu.module.scss";

export interface MenuItemProps {
  key: string;
  component?: ReactNode;
  disabled?: boolean;
  disabledText?: string;
  selfContained?: boolean;
  callback?: () => void;
  topBorder?: boolean;
}

export interface MenuBtnProps {
  buttonNode: ReactNode;
  menuItems: MenuItemProps[];
  menuDropdownPlacement?: Placement;
}

const DropdownMenu = ({
  buttonNode,
  menuItems,
  menuDropdownPlacement = "bottom-end"
}: MenuBtnProps) => {
  // popper intentionally uses state, not ref, to update when dom node changes
  const [referenceEl, setReferenceEl] = useState<HTMLButtonElement | null>();
  const [menuItemsContainer, setMenuItemsContainer] = useState<HTMLDivElement | null>();

  const { styles: popperStyles, attributes } = usePopper(referenceEl, menuItemsContainer, {
    placement: menuDropdownPlacement,
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, 8] }
      }
    ]
  });

  return (
    <div className={classNames(styles.menuContainer)}>
      <Menu>
        <Menu.Button
          ref={(node) => setReferenceEl(node)}
          as="div"
          role="button"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
          }}
        >
          {buttonNode}
        </Menu.Button>
        {menuItems?.length > 0 &&
          ReactDOM.createPortal(
            <Menu.Items
              ref={setMenuItemsContainer}
              className={classNames(styles.dropdownMenu, "show", "dropdown-menu")}
              style={popperStyles.popper}
              {...attributes.popper}
            >
              {menuItems.map((menuItem) => (
                <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
                  {({ close }) =>
                    menuItem.selfContained ? (
                      menuItem.component
                    ) : (
                      <Tooltip message={menuItem.disabled && menuItem.disabledText}>
                        <div
                          onClick={(e) => {
                            menuItem.callback?.();
                            close();
                            e.stopPropagation();
                          }}
                          className={classNames(
                            styles.dropdownItem,
                            "dropdown-item",
                            menuItem.topBorder && styles.border,
                            menuItem.disabled && styles.disabled,
                            menuItem.disabled && "disabled"
                          )}
                        >
                          {menuItem.component}
                        </div>
                      </Tooltip>
                    )
                  }
                </Menu.Item>
              ))}
            </Menu.Items>,
            document.body
          )}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
