import { CellProps } from "react-table";
import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { Spinner } from "@components/spinner/spinner";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./tableCell.module.scss";

export const TableIconSpinnerCell = <T extends object>({
  value,
  iconName,
  color,
  isLoading,
  tooltipText
}: CellProps<T>) => {
  return (
    <div
      className={classNames(styles["table-cell"], "d-flex gap-s align-items-center")}
      title={typeof value === "string" ? value : undefined}
    >
      <Tooltip message={tooltipText} placement="top">
        <div>
          {(isLoading && <Spinner size={"extra-tiny-small"} />) || (
            <EmblaIcon iconName={iconName} color={color} />
          )}{" "}
        </div>
      </Tooltip>
      {value}
    </div>
  );
};
